// override plugin_merge version as it incorrectly exports ALL
// base.js methods
'use strict';

var base = require('./base');
var detail = require('base/product/detail');
var throttle = require('lodash/throttle');
var detailUpdateAddToCartEnableDisableOtherElements = detail.methods.updateAddToCartEnableDisableOtherElements;

/**
 * Registering on change event on quantity selector. If store has been selected, exist function,
 * otherwise proceed to update attributes.
 */
function availability() {
    $(document).on('change', '.quantity-select', function (e) {
        e.preventDefault();

        var $productContainer = $(this).closest('.product-detail');
        if (!$productContainer.length) {
            $productContainer = $(this).closest('.modal-content').find('.product-quickview');
        }

        // Display spinner on the cart edit item modal when quantity change
        if ($('#editProductModal:visible').length > 0) {
            $('.modal-body').spinner().start();
        }

        if ($('.bundle-items', $productContainer).length === 0) {
            if ($(this).hasClass('qty-number-input')) {
                var $numberInput = $(e.currentTarget);
                var updatedUrl = $numberInput.data('url').replace(/quantity=[0-9]*/, 'quantity='+$numberInput.val());
                base.attributeSelect(updatedUrl, $productContainer);
            } else {
                base.attributeSelect($(e.currentTarget).find('option:selected').data('url'), $productContainer);
            }
        }
    });
}

/**
 * Enable/disable Buy Now buttons based on product availability
 * @param {boolean} enableOrDisable - true or false
 */
function updateAddToCartEnableDisableOtherElements(enableOrDisable) {
    detailUpdateAddToCartEnableDisableOtherElements.call(this, enableOrDisable);
    $('.salesforce-buynow-element').attr('disabled', enableOrDisable);
}

detail.updateAddToCart = () => {
    $('body').on('product:updateAddToCart', function (e, response) {
        // update local add to cart (for sets)
        var isDisabled = (!response.product.readyToOrder || !response.product.available);
        var isReadyToOrder = response.product.readyToOrder;
        var selectedmorethanavailable = response.product.availability && response.product.availability.selectedmorethanavailable ? response.product.availability.selectedmorethanavailable : false;
        // toggle class to allow button to still be tabbed/clicked
        $('button.add-to-cart', response.$productContainer)
            .toggleClass('disabled', isDisabled);

        $('button.add-to-cart', response.$productContainer)
            .attr('data-ready-to-order', isReadyToOrder);

        if (selectedmorethanavailable && isDisabled) {
            $('.product-ship-method-quantity-availability .text-danger').html(response.resources.selectedmorethanavailable);
        } else {
            $('.product-ship-method-quantity-availability .text-danger').html('');
        }

        if (isDisabled) {
            var currentSize = response.product.size;
            var currentColor = response.product.color;
            if (currentSize) {
                $(".product-attributes span[data-attr-value='" + currentSize + "']").removeClass('selected');
                $(".product-attributes span[data-attr-value='" + currentSize + "']").addClass('unselectable');
            }
            if (currentColor) {
                $(".product-attributes span[data-attr-value='" + currentColor + "']").removeClass('selected');
                $(".product-attributes span[data-attr-value='" + currentColor + "']").addClass('unselectable');
            }
        }
        // update CTA copy based on buttons state
        if(isDisabled && !isReadyToOrder) {
            $('button.add-to-cart', response.$productContainer)
                .text(response.resources.selectCTA);
        } else {
            $('button.add-to-cart', response.$productContainer)
                .trigger('product:shippingMethodChanged', {
                    $selectedMethod: response.$productContainer.find('.product-ship-method input:checked'),
                    $productContainer: response.$productContainer
                });
        }

        var enable = $('.product-availability').toArray().every(function (item) {
            return $(item).data('available') && $(item).data('ready-to-order');
        });
        module.exports.methods.updateAddToCartEnableDisableOtherElements(!enable);
    });
}

/**
 * Initialize the quantity change functionality that will disable/enable the update to cart button
 */
detail.cartUpdateAddToCart = () => {
    $('#editProductModal').on('shown.bs.modal', function () {
        $('body').on('product:updateAddToCart', function (e, response) {
            // update local add to cart (for sets)
            var isDisabled = (!response.product.readyToOrder || !response.product.available);
            var isReadyToOrder = response.product.readyToOrder;

            // toggle class to allow button to still be tabbed/clicked
            $('button.update-cart-product-global', response.$productContainer)
                .toggleClass('disabled', isDisabled);

            $('button.update-cart-product-global', response.$productContainer)
                .attr('data-ready-to-order', isReadyToOrder);

            // update CTA copy based on buttons state
            if(isDisabled && !isReadyToOrder) {
                $('button.update-cart-product-global', response.$productContainer)
                    .text(response.resources.selectCTA);
            } else {
                $('button.update-cart-product-global', response.$productContainer)
                    .trigger('product:shippingMethodChanged', {
                        $selectedMethod: response.$productContainer.find('.product-ship-method input:checked'),
                        $productContainer: response.$productContainer
                    });
            }

            var enable = $('.product-availability').toArray().every(function (item) {
                return $(item).data('available') && $(item).data('ready-to-order');
            });
            module.exports.methods.updateAddToCartEnableDisableOtherElements(!enable);
        });
    });
}

var toggleFixedAtcThrottled = throttle(function () {
    var $productAtc = $('.js-fixed-atc');
    var isScrolled = $('.product-attributes')[0].getBoundingClientRect().top < -320;
    $productAtc.toggleClass('is-not-fixed', !isScrolled);
}, 100);

detail.toggleFixedAtc = function () {
    $('.js-fixed-atc').addClass('is-not-fixed'); // By adding class on init, we set default behavior when JS is disabled as the standard fixed position on mobile
    $(document).on('scroll', toggleFixedAtcThrottled);
};

detail.eddDate = function (e) {
    $('.edd-part4').on ('click', function (e) {
        e.preventDefault();
        $(".zip-code-field-edd").css("display", "flex");
    }) 
}

var exportDetails = $.extend(
                {},
                detail,
                {
                    addToCart: base.addToCart,
                    availability: availability,
                    handleMixedBagSelection: base.handleMixedBagSelection,
                    handleClearMinicartTimeout: base.handleClearMinicartTimeout
                }
            );
exportDetails.methods.updateAddToCartEnableDisableOtherElements = updateAddToCartEnableDisableOtherElements;

module.exports = exportDetails;
