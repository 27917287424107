'use strict';

function flyoutFocusOut(e) {
    if (!$(e.target).is('[data-toggle=flyout]') && $('.js-flyout.show').length) {
        $('.js-flyout.show').each(function (ind, flyout) {
            const $flyout = $(flyout);
            let $flyoutWrapper = $flyout.closest('.js-flyout-wrapper');
            $flyoutWrapper = $flyoutWrapper.length ? $flyoutWrapper : $flyout;

            if (!$flyoutWrapper.has(e.target).length) {
                $flyout.removeClass('show')
                    .trigger('flyout.hidden');
            }
        });
    }
}

function trapFocus (e, firstFocusableEl, lastFocusableEl) {
    if (e.keyCode === 9) {
      //Rotate Focus
      if (e.shiftKey && document.activeElement === firstFocusableEl) {
        e.preventDefault();
        lastFocusableEl.focus();
      } else if (!e.shiftKey && document.activeElement === lastFocusableEl) {
        e.preventDefault();
        firstFocusableEl.focus();
      }
    }
  };

module.exports = {
    showFlyout: function () {
        $('[data-toggle=flyout]').on('click', function () {
            const target = $(this).data('target');
            if (target) {
                $(target).addClass('show');
                $(target).trigger('flyout.shown', {elem: $(target)});
            }
        })
    },
    closePanel: function () {
        $('body').on('click', '.js-panel-close', function () {
            $(this).closest('.js-flyout').removeClass('show')
                .trigger('flyout.hidden');
        });

        // Set up listeners only when needed
        $('body').on('flyout.shown', function () {
            // Prevent multiple listeners
            $('body').off('touchstart.flyout click.flyout focus.flyout', flyoutFocusOut);
            $('body').on('touchstart.flyout click.flyout focus.flyout', flyoutFocusOut);
        });

        // Stop listening once all flyouts are closed
        $('body').on('flyout.hidden', function () {
            if (!$('.js-flyout.show').length) {
                $('body').off('touchstart.flyout click.flyout focus.flyout', flyoutFocusOut);
            }
        });
    },
    toggleModalBg: function () {
        $('body').on('flyout.shown', function (e, data) {
            $('.modal-background').addClass('d-block')
                .addClass('modal-background--flyout');
            if (data && data.elem && data.elem.length) {
                data.elem[0].focus();
            }
        })
            .on('flyout.hidden', function () {
                if (!$('.js-flyout.show').length) {
                    $('.modal-background').removeClass('d-block')
                        .removeClass('modal-background--flyout');
                }
            });
    },
    applyFocusTrap: function () {
        $('body').on('flyout.shown', function (e) {
            var $focusables = $(e.target).find('a, button, input, select, textarea, [tabindex]');
            if ($focusables.length) {
                $(e.target).on('keydown.flyout', function (f) {
                    trapFocus(f, $focusables.first()[0], $focusables.last()[0]);
                });
            }
        }).on('flyout.hidden', function (e) {
            $(e.target).off('keydown.flyout');
        });
    }
};