'use strict';

module.exports = function () {
    $('.info-icon').on('mouseenter focusin', function () {
        const windowWidth = window.innerWidth; // Get before showing to get unaffected value in case of overflow
        const $tooltip = $(this).find('.tooltip');
        let tooltipOffset = parseFloat(getComputedStyle($tooltip[0]).getPropertyValue('--tooltip-left'), 10) || 0;
        $tooltip.removeClass('d-none');

        // Generally we can assume only one side is overflowing the page
        if ($tooltip.offset().left < 0) {
            tooltipOffset += ($tooltip.offset().left * -1);
        } else if (($tooltip.offset().left + $tooltip.outerWidth()) > windowWidth) {
            tooltipOffset += (windowWidth - ($tooltip.offset().left + $tooltip.outerWidth()));
        }

        $tooltip[0].style.setProperty('--tooltip-left', tooltipOffset + 'px');
    });

    $('.info-icon').on('mouseleave focusout', function () {
        $(this).find('.tooltip').addClass('d-none');
    });
};
