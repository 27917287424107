window.jQuery = window.$ = require('jquery');
var processInclude = require('base/util');

$(document).ready(function () {
    processInclude(require('./components/global'));
    processInclude(require('./components/menu'));
    processInclude(require('base/components/cookie'));
    //processInclude(require('base/components/consentTracking'));
    processInclude(require('base/components/footer'));
    processInclude(require('./components/miniCart'));
    processInclude(require('./components/collapsibleItem'));
    processInclude(require('base/components/clientSideValidation'));
    processInclude(require('base/components/countrySelector'));
    processInclude(require('./components/toolTip'));
    processInclude(require('./productTile'));
    processInclude(require('./components/search'));
    processInclude(require('./components/flyout'));

    var tagManager = require('adobe/tagManager');
    tagManager.init();
});

require('./thirdParty/bootstrap');
require('base/components/spinner');
