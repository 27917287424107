'use strict';

var keyboardAccessibility = require('./keyboardAccessibility');
var lastScrollTop = 0;
var headerIsAnchoredUnderPromo = false;

var clearSelection = function (element) {
    $(element).closest('.dropdown').children('.dropdown-menu').children('.top-category')
        .detach();
    $(element).closest('.dropdown.show').children('.nav-link').attr('aria-expanded', 'false');
    $(element).closest('.dropdown.show').children('.dropdown-menu').attr('aria-hidden', 'true');
    $(element).closest('.dropdown.show').removeClass('show');
    $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'false');
    $(element).closest('li').detach();
};

/**
 *  Ensure element doesn't run off page
 */
function positionLoginCta() {
    const $loginCta = $('.js-login-cta');
    const edgeOffset = 8;

    if ($loginCta.length) {
        const windowWidth = $(window).width(); // Get before showing to get unaffected value
        let popupOffset = parseFloat(getComputedStyle($loginCta[0]).getPropertyValue('--popup-left'), 10) || 0;
        const offsetLeft = $loginCta.offset().left;
        const offsetLeftWidth = offsetLeft + $loginCta.outerWidth()

        // Generally we can assume only one side is overflowing the page
        if (offsetLeft < 0) {
            popupOffset += ((offsetLeft * -1));
        } else if (offsetLeftWidth > windowWidth) {
            popupOffset += (windowWidth - offsetLeftWidth);
        }

        // Shift edge offset separately, for cases where the CTA is inside the edge already, but less than the offset
        if (offsetLeft < edgeOffset) {
            popupOffset += edgeOffset - Math.max(0, offsetLeft)
        } else if (offsetLeftWidth > (windowWidth - edgeOffset)) {
            popupOffset += (edgeOffset - Math.max(0, (windowWidth - offsetLeftWidth))) * -1;
        }

        $loginCta[0].style.setProperty('--popup-left', popupOffset + 'px');
    }
}

module.exports = function () {
    var isDesktop = function (element) {
        return $(element).parents('.menu-toggleable-left').css('position') !== 'fixed';
    };

    var headerBannerStatus = window.sessionStorage.getItem('hide_header_banner');
    $('.header-banner .close').on('click', function () {
        $('.header-banner').addClass('d-none');
        window.sessionStorage.setItem('hide_header_banner', '1');
    });

    if (!headerBannerStatus || headerBannerStatus < 0) {
        $('.header-banner').removeClass('d-none');
    }
    // Firefox and chrome behave differently
    var scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollPosition <= 20 && $('.header-banner').length) {
        var bannerHeight = $('.header-banner').innerHeight();
        $('.header--general').css({ top: bannerHeight });
    }

    keyboardAccessibility('.main-menu .nav-link, .main-menu .dropdown-link',
        {
            40: function (menuItem) { // down
                if (menuItem.hasClass('nav-item')) { // top level
                    $('.navbar-nav .show').removeClass('show')
                        .children('.dropdown-menu')
                        .removeClass('show');
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                } else {
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    if (!(menuItem.next().length > 0)) { // if this is the last menuItem
                        menuItem.parent().parent().find('li > a') // set focus to the first menuitem
                            .first()
                            .focus();
                    } else {
                        menuItem.next().children().first().focus();
                    }
                }
            },
            39: function (menuItem) { // right
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.next().children().first().focus();
                } else if (menuItem.hasClass('dropdown')) {
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    $(this).attr('aria-expanded', 'true');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                }
            },
            38: function (menuItem) { // up
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                } else if (menuItem.prev().length === 0) { // first menuItem
                    menuItem.parent().parent().removeClass('show')
                        .children('.nav-link')
                        .attr('aria-expanded', 'false');
                    menuItem.parent().children().last().children() // set the focus to the last menuItem
                        .first()
                        .focus();
                } else {
                    menuItem.prev().children().first().focus();
                }
            },
            37: function (menuItem) { // left
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.prev().children().first().focus();
                } else {
                    menuItem.closest('.show').removeClass('show')
                        .closest('li.show').removeClass('show')
                        .children()
                        .first()
                        .focus()
                        .attr('aria-expanded', 'false');
                }
            },
            27: function (menuItem) { // escape
                var parentMenu = menuItem.hasClass('show')
                    ? menuItem
                    : menuItem.closest('li.show');
                parentMenu.children('.show').removeClass('show');
                parentMenu.removeClass('show').children('.nav-link')
                    .attr('aria-expanded', 'false');
                parentMenu.children().first().focus();
            }
        },
        function () {
            return $(this).parent();
        }
    );

    $('.dropdown:not(.disabled) [data-toggle="dropdown"]')
        .on('click', function (e) {
            if (!isDesktop(this)) {
                $('.modal-background').show();
                // copy parent element into current UL
                var li = $('<li class="dropdown-item top-category" role="button"><div class="chevron back"></div></li>');
                var link = $(this).clone().removeClass('dropdown-toggle')
                    .removeAttr('data-toggle')
                    .removeAttr('aria-expanded')
                    .attr('aria-haspopup', 'false');
                li.append(link);
                var closeMenu = $('<li class="nav-menu"></li>');
                closeMenu.append($('.close-menu').first().clone());
                $(this).parent().children('.dropdown-menu')
                    .prepend(li)
                    .prepend(closeMenu)
                    .attr('aria-hidden', 'false');
                // copy navigation menu into view
                $(this).parent().addClass('show');
                $(this).attr('aria-expanded', 'true');
                $(link).focus();
                $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'true');
                e.preventDefault();
            }
        })
        .on('mouseenter', function () {
            if (isDesktop(this)) {
                var eventElement = this;
                $('.navbar-nav > li').each(function () {
                    if (!$.contains(this, eventElement)) {
                        $(this).find('.show').each(function () {
                            clearSelection(this);
                        });
                        if ($(this).hasClass('show')) {
                            $(this).removeClass('show');
                            $(this).children('ul.dropdown-menu').removeClass('show');
                            $(this).children('.nav-link').attr('aria-expanded', 'false');
                        }
                    }
                });
                // need to close all the dropdowns that are not direct parent of current dropdown
                $(this).parent().addClass('show');
                $(this).siblings('.dropdown-menu').addClass('show');
                $(this).attr('aria-expanded', 'true');
            }
        })
        .parent()
        .on('mouseleave', function () {
            if (isDesktop(this)) {
                $(this).removeClass('show');
                $(this).children('.dropdown-menu').removeClass('show');
                $(this).children('.nav-link').attr('aria-expanded', 'false');
            }
        });

    $('.navbar>.close-menu .close-button').on('click', function (e) {
        e.preventDefault();
        $('.menu-toggleable-left').removeClass('in');
        $('.modal-background').hide();

        $('.navbar-toggler').focus();

        $('.main-menu').attr('aria-hidden', 'true');
        $('.main-menu').siblings().attr('aria-hidden', 'false');
        $('header').siblings().attr('aria-hidden', 'false');

        $('body').removeClass('menu-fixed');
    });

    $('.chevron.back').on('click', function (e) {
        $(this).closest('.navbar-nav .back').trigger('click');
    });

    $('.navbar-nav').on('click', '.back', function (e) {
        e.preventDefault();
        if ($(this).hasClass('chevron')) {
            var $this = $(this).closest('.dropdown-menu').find('.nav-menu .close-menu .back');
        } else {
            var $this = $(this);
        }

        clearSelection($this);

    });

    $('.navbar-nav').on('click', '.close-button', function (e) {
        e.preventDefault();
        $('.navbar-nav').find('.top-category').detach();
        $('.navbar-nav').find('.nav-menu').detach();
        $('.navbar-nav').find('.show').removeClass('show');
        $('.menu-toggleable-left').removeClass('in');

        $('.main-menu').siblings().attr('aria-hidden', 'false');
        $('header').siblings().attr('aria-hidden', 'false');

        $('.modal-background').hide();

        $('body').removeClass('menu-fixed');
    });

    $('.navbar-toggler').click(function (e) {
        e.preventDefault();
        $('.main-menu').toggleClass('in');
        $('.modal-background').show();

        $('.main-menu').removeClass('d-none');
        $('.main-menu').attr('aria-hidden', 'false');
        $('.main-menu').siblings().attr('aria-hidden', 'true');
        $('header').siblings().attr('aria-hidden', 'true');

        $('.main-menu .nav.navbar-nav .nav-link').first().focus();

        $('body').addClass('menu-fixed');
    });

    $('.header-utility .search-icon').on('click', function (e) {
        if ($(window).width() <= 768) {
            $('body').addClass('menu-fixed');
        }

        if ($(this).hasClass('menu-search') && $('.header-banner').length) {
            var height = '-' + $('.header-banner').height() + 'px';
            $('.header-utility .search').css('top', height)
        }

        $('.header-utility .search .search-field').val("");
        $('.header-utility .search .search-field').focus();

        $('.header-utility .search').toggleClass('show');
        $('.search-close').show();
    });

    $('.header-utility .search-close').on('click', function () {
        if ($(window).width() <= 768) {
            $('body').removeClass('menu-fixed');
        }
        $('.header-utility .search').removeClass('show');
        $('body').removeClass('modal-open');
        $('.header-utility .search-icon a').focus();
        $('.search-close').hide();
    });

    keyboardAccessibility('.navbar-header .user',
        {
            40: function ($popover) { // down
                if ($popover.children('a').first().is(':focus')) {
                    $popover.next().children().first().focus();
                } else {
                    $popover.children('a').first().focus();
                }
            },
            38: function ($popover) { // up
                if ($popover.children('a').first().is(':focus')) {
                    $(this).focus();
                    $popover.removeClass('show');
                } else {
                    $popover.children('a').first().focus();
                }
            },
            27: function () { // escape
                $('.navbar-header .user .popover').removeClass('show');
                $('.user').attr('aria-expanded', 'false');
            },
            9: function () { // tab
                $('.navbar-header .user .popover').removeClass('show');
                $('.user').attr('aria-expanded', 'false');
            }
        },
        function () {
            var $popover = $('.user .popover li.nav-item');
            return $popover;
        }
    );

    $('.navbar-header .user').on('mouseenter focusin', function () {
        if ($('.navbar-header .user .popover').length > 0) {
            $('.navbar-header .user .popover').addClass('show');
            $('.user').attr('aria-expanded', 'true');
        }
    });

    $('.navbar-header .user').on('mouseleave', function () {
        $('.navbar-header .user .popover').removeClass('show');
        $('.user').attr('aria-expanded', 'false');
    });
    $('body').on('click', '#myaccount', function () {
        event.preventDefault();
    });
    if (!$('#checkout-main').length) {
        // Nav hide/display on scroll
        window.onscroll = () => {
            // Firefox and chrome behave differently
            var scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
            var isBackButton = $('#isBackButton').attr('data-isbackbutton');
            var bannerHeight = $('.header-banner').innerHeight();
            if (isBackButton === 'true') {
                $('#isBackButton').attr('data-isbackbutton','false');
                return;
            }
            
            if ($(window).scrollTop() + window.innerHeight >= $(document).height() - 100) {
                return;
            }

            if (scrollPosition > lastScrollTop && scrollPosition > (bannerHeight + 3)) {
                $('.dropdown-menu').removeClass('show');
                $('.header--general').css({ top: '-90px' })
                    .css('transition', '');
                headerIsAnchoredUnderPromo = false;
                if (!$('#maincontent').hasClass('no-padding')) {
                    $('#maincontent').addClass('no-padding');
                }
            } else {
                if ($('#maincontent').hasClass('no-padding')) {
                    $('#maincontent').removeClass('no-padding');
                }
                if (scrollPosition <= (bannerHeight + 2) && $('.header-banner').length) {
                    $('.header--general').css({ top: (bannerHeight - scrollPosition) })
                        .css('transition', headerIsAnchoredUnderPromo ? 'none' : ''); // Prevent jerkiness when locking header to bottom edge of promo
                    headerIsAnchoredUnderPromo = true;
                } else {
                    $('.header--general').css({ top: '0' })
                        .css('transition', '');
                    headerIsAnchoredUnderPromo = false;
                }
            }

            // For Mobile or negative scrolling
            lastScrollTop = scrollPosition <= 50 ? 50 : scrollPosition;
        };
    }

    const $loginCta = $('.js-login-cta');
    var loginCtaStatus = parseInt(window.sessionStorage.getItem('hide_login_cta'), 10);
    if (!loginCtaStatus || isNaN(loginCtaStatus)) {
        $loginCta.addClass('show');

        positionLoginCta();
        
        $('body').one('click', function () {
            $loginCta.removeClass('show');
            window.sessionStorage.setItem('hide_login_cta', '1');
        });      
    } else {
        $('.js-account-link').one('mouseenter', positionLoginCta);
    }
};
