// Code to run for Global
var Splide = require('@splidejs/splide').default; //dependency include
/**
 * Initialize stepper component
 */
var customNumberInput = () => {
    $('body').on(
        'click',
        '.number-input .qty-btn-minus, .number-input .qty-btn-plus',
        (e) => {
            e.preventDefault();

            var $direction = $(e.currentTarget);
            var $numberInput = $direction.siblings('input');

            if ($direction.data('direction') === "plus") {
                $numberInput[0].stepUp();
            } else {
                $numberInput[0].stepDown();
            }
            $numberInput.change();
        }
    );
}

/**
 * Global header carousel
 */
var globalHeaderCarousel = () => {
    var $headerCarouselContainer = $('.global-promo-banner__carousel');
    if ($headerCarouselContainer.length) {
        new Splide(
            $headerCarouselContainer[0],
            {
                type: 'loop',
                pagination: false,
                autoplay: true,
                interval: 5000,
                arrows: false
            })
            .mount();
    }
}

/**
 * Global show/hide component for password fields
 */
var passwordVisibility = () => {
    $('.password-visibility--btn').on('click keypress', function (evt) {

        var inputTarget = $(this).prev();
        var keycode = (evt.keyCode ? evt.keyCode : evt.which);

        if (typeof inputTarget === 'undefined') return;

        // a11y - Enter/Space
        if (keycode === '13' || evt.key === '32') {
            this.click()
        }

        if (inputTarget[0].tagName.toLocaleLowerCase() === 'input') {
            if (inputTarget.attr("type") == "password") {
                inputTarget.attr("type", "text");
                this.innerText = 'Hide';
            } else {
                inputTarget.attr("type", "password");
                this.innerText = 'Show';
            }
        }
    })
}

/**
 * Handles callback by mutation observer.
 * @param {*} mutations
 * @param {*} observer
 */
var initEinsteinCarousel = (mutations, observer) => {

    mutations.forEach(function (mutation) {
        if (mutation.type === 'childList' && mutation.addedNodes.length) {
            // if recommendation carousel markup exists, instantiate carousel
            var $recCarousel = $(mutation.target).find('.recommendations.recommendations-carousel');
            if ($recCarousel.length) {
                observer.disconnect();
                var $carousel = $recCarousel.find('.splide');
                new Splide(
                    $carousel[0],
                    {
                        pagination: false,
                        perPage: 4,
                        gap: 8,
                        autoHeight: true,
                        breakpoints: {
                            991: {
                                perPage: 2,
                            },
                        }
                    })
                    .mount();
                $('body').trigger('tagmanager:recommendationsLoaded');
            }
        }
    });
};

/**
 * Will listen inside any .recommendation-container carousels
 * and instantiate a slick carousel once once recommendations are inserted.
 * Assumes the carousel_4x1.isml rendering template is being used by the slot
 * @returns
 */
var globalEinsteinCarousel = () => {
    // look for all recommendation carousel containers
    var $recContainers = $('.recommendation-container')
    var config = { childList: true, subtree: true };

    if (!$recContainers.length || !('MutationObserver' in window)) {
        return;
    }

    $recContainers.each(function () {
        // Start observing the target node for configured mutations
        var observer = new MutationObserver(initEinsteinCarousel);
        observer.observe(this, config);
    });


}

module.exports = {
    customNumberInput: customNumberInput,
    globalHeaderCarousel: globalHeaderCarousel,
    passwordVisibility: passwordVisibility,
    globalEinsteinCarousel: globalEinsteinCarousel
}