'use strict';
/* global pageContext */

window.appEventData = window.appEventData || [];
window.atmQueuedEvents = window.atmQueuedEvents || [];
if(!dataLayer) {
    var dataLayer = window.appEventData;
}
var queuedEvents = window.atmQueuedEvents;
const $atmIsEnabled = $('#atmIsEnabled');
const ATM_ENABLED = !!($atmIsEnabled.length && $atmIsEnabled.val() === 'true');
var tagManagerInitCompleted = false;

function forwardEvents(events) {
    try {
        if(events && events.length > 0) {
            events.forEach(function(event) {
                dataLayer.push(event);
            });
        }
    } catch(e) {
        console.warn('' + e);
    }
}

/**
 * @param {Object} productObject The product data
 * @param {string} position - position
 * @description gets the data for a product click
 */
function productTileClick(productObject, position) {
    if (!dataLayer || !ATM_ENABLED) return;
    var priceType = productObject.priceType;
    delete productObject.priceType;
    delete productObject.brand;
    delete productObject.price;
    delete productObject.category;
    var obj = {};
    obj.event = 'Product Listing Item Clicked';
    obj.listingItemClicked = {
        listing: [
            {
                itemPosition: position,
                price: {
                    priceType: priceType
                },
                productInfo: productObject
            }
        ]
    };
    dataLayer.push(obj);
}

/**
 * @description Click event for add product to cart
 * @param {Object} data The product data
 * @param {string} quantity product quantity
 */
function addToCart(data) {
    if (!dataLayer || !ATM_ENABLED) return;

    if (data) {
        dataLayer.push(data);
    }
}

/**
 * Updates the current step in the checkout flow
 * @param {Integer} step the step number the flow is currently on
 * @param {Object} data - gtm data
 */
function updateCheckoutStep(step, data) {
    if (!dataLayer || !ATM_ENABLED) return;

    var obj = {
        event: 'checkout',
        ecommerce: {
            checkout: {
                actionField: {
                    step: step,
                    action: 'checkout'
                },
                products: data.products
            },
            currencyCode: data.products.currencyCode,
            orderTotal: data.products.orderTotal
        }
    };
    dataLayer.push(obj);
}

function CTALinkEvent(linkContainer, linkId, linkPage, linkRegion) {
    dataLayer.push({
        event: 'CTA Link Clicked',
        linkInfo: {
            linkContainer: linkContainer || '',
            linkId: linkId || '',
            linkPage: linkPage || '',
            linkRegion: linkRegion || ''
        }
    });
}

function getSearchUpdateGridEvent(event, url) {
    var qs = {};
    var sortOrder = '';

    var urlParts = url.split('?');
    var params = urlParts.length > 1 && urlParts[1].split('&') || [];
    params.forEach(function(param) {
        var paramParts = param.split('=');
        if (paramParts.length > 1) {
            if (paramParts[0] === 'srule') {
                sortOrder = paramParts[1];
            } else {
                qs[paramParts[0]] = paramParts[1];
            }
        }
    });

    var filters = [];
    Object.keys(qs).forEach(function (key) {
        var m = key.match(/^prefn(\d+)/);
        if(m !== null && m.length > 1) {
            var i = m[1];
            filters.push(qs[key] + '~' + qs['prefv' + i]);
        }
    });
    
    return {
        event: event,
        listingRefined: {
            filterList: filters.join('|'),
            listingType: 'Product',
            sortOrder: qs.srule || sortOrder
        }
    };
}

function getListingItemsFromGLEProduct(gleDetails) {
    var shippingCountry = gleDetails.country && gleDetails.country.code || 'N/A';

    return gleDetails.products.map(function (product) {
        var category = product.categories && product.categories.length > 0
            && product.categories[0].Name || '';

        var color, size, style;

        if (product.MetaData && product.MetaData.Attributes
            && product.MetaData.Attributes.length > 0) {
            product.MetaData.Attributes.forEach(function (Attribute) {
                if (Attribute.AttributeKey == 'color') {
                    color = Attribute.AttributeValue;
                } else if (Attribute.AttributeKey == 'size') {
                    size = Attribute.AttributeValue;
                } else if (Attribute.AttributeKey == 'style') {
                    style = Attribute.AttributeValue;
                }
            });
        }

        var productLevelDiscountAmount, productLevelDiscountCode, productLevelDiscountCodeCampaign;

        gleDetails.discounts.forEach(function (discount) {
            if (discount.productCartItemId == product.cartItemId) {
                productLevelDiscountAmount = discount.DiscountPrices.CustomerTransaction.CustomerPrice;
                productLevelDiscountCode = discount.CouponCode;
                productLevelDiscountCodeCampaign = discount.Description;
            }
        });

        return {
            fulfillment: {
                method: 'Shipped',
                source: 'GlobalE',
                storeID: '' + gleDetails.MerchantID
            },
            price: {
                priceType: '',
                sellingPrice: '' + product.customerPrice
            },
            productInfo: {
                category: category,
                name: product.name || '',
                productID: product.sku || '',
                color: color || '',
                size: size || '',
                style: style || '',
                sku: product.sku || '',
                thirdyPartyVendorID: 'GlobalE'
            },
            quantity: product.quantity,
            shippingAddress: {
                country: shippingCountry
            },
            shippingMethod: gleDetails.shippingServiceName  || '',
            tax: 'vatRate' in product && product.vatRate || '',
            voucherDiscount: {
                orderLevelDiscountAmount: '',
                orderLevelDiscountCode: '',
                orderLevelDiscountCodeCampaign: '',
                productLevelDiscountAmount: productLevelDiscountAmount || '',
                productLevelDiscountCode: productLevelDiscountCode || '',
                productLevelDiscountCodeCampaign: productLevelDiscountCodeCampaign || ''
            }
        };
    });
}

var events = {};
if (ATM_ENABLED) {
    events = {
        account: function () { },
        cart: function () {
            $('body').on('promotion:success promotion:error', function (e, data) {
                if (data && data.atmEvents) {
                    var atmEvents = data.atmEvents;
                    atmEvents.forEach(function (atmEvent) {
                        dataLayer.push(atmEvent);
                    });
                }
            });
        },
        checkout: function () {
            $('body').on('tagmanager:updateCheckoutStep', function (e, data) {
                if (data && data.atmEvents) {
                    var atmEvents = data.atmEvents;
                    atmEvents.forEach(function (atmEvent) {
                        dataLayer.push(atmEvent);
                    });
                }
            });
        },
        contactUs: function () {
            $('form.contact-us').submit(function (e) {
                var contactTopic = $(this).find('#contact-topic option:selected').text().trim();

                dataLayer.push({
                    event: 'Contact Us Completed',
                    contact: {
                        contactPurpose: contactTopic || ''
                    }
                });
            });
        },
        home: function () {
            var linkPage = 'Home Page';

            $('.home-main-categories a').on('click', function () {
                var categoryTextElement = $(this).find('.hp-category-message');
                var categoryTileElement = $(this).find('.category-tile');

                var linkContainer = categoryTextElement.length > 0 && categoryTextElement[0].innerText || '';
                var linkId = categoryTileElement.length > 0 && categoryTileElement[0].classList[0] || '';

                CTALinkEvent(linkContainer, linkId, linkPage, 'Categories');
            });

            $('.home-product-tiles a.quickview').on('click', function () {
                var pdpLinkElement = $(this).closest('.product-tile').find('.pdp-link a');
                var pid = $(this).closest('div.product').data('pid');

                var linkContainer = pdpLinkElement.length > 0 && pdpLinkElement[0].innerText || '';
                var linkId = pid && 'quick-add-' + pid || '';

                CTALinkEvent(linkContainer, linkId, linkPage, 'Products');
            });

            $('.homepage.shop-the-style').on('click', function () {
                var titleElement = $(this).find('.page-title');

                var linkContainer = titleElement.length > 0 && titleElement[0].innerText || '';

                CTALinkEvent(linkContainer, '', linkPage, 'Shop The Style');
            });
        },
        product: function () {
            var productLocationListingDetails;

            $('body').on('product:afterAttributeSelect', function (e, data) {
                if (data && data.data && data.data.atmEvents) {
                    var atmEvents = data.data.atmEvents;
                    atmEvents.forEach(function (atmEvent) {
                        dataLayer.push(atmEvent);
                    });
                }
            });

            $('body').on('tagmanager:productLocationListingDisplayed', function (e, atmEvents) {
                try {
                    if (atmEvents) {
                        atmEvents.forEach(function (atmEvent) {
                            if (atmEvent.event === 'Product Location Listing Displayed') {
                                productLocationListingDetails = {
                                    price: atmEvent.listingDisplayed.listing[0].price,
                                    productInfo: atmEvent.listingDisplayed.listing[0].productInfo
                                };
                            }
                            dataLayer.push(atmEvent);
                        });
                    }
                } catch(e) {
                    console.warn(e);
                }                
            });

            $('body').on('click.tagmanager', '#storeSearchModal .custom-radio input[type=radio]', function () {
                try {
                    var storeInfo = $(this).data('store-info');
                    var index = $(this).closest('.card').index() + 1;
                    var price = productLocationListingDetails.price;
                    var productInfo = productLocationListingDetails.productInfo;

                    var event = {
                        event: "Product Location Listing Item Clicked",
                        listingItemClicked: {
                            listing: [
                                {
                                    itemPosition: index,
                                    location: {
                                        locationId: storeInfo.ID,
                                        locationName: storeInfo.name,
                                        locationType: "Retail Store"
                                    },
                                    price: price,
                                    productInfo: productInfo
                                }
                            ]
                        }
                    };

                    dataLayer.push(event);
                } catch(e) {
                    console.warn(e);
                }                
            });
        },
        login: function () {
            $('form.registration').on('login:register:success', function (e, data) {
                if (data && data.atmEvents) {
                    var atmEvents = data.atmEvents;
                    atmEvents.forEach(function (atmEvent) {
                        dataLayer.push(atmEvent);
                    });
                }
            });

            $('form.login').on('login:success login:error', function (e, data) {
                if (data && data.atmEvents) {
                    var atmEvents = data.atmEvents;
                    atmEvents.forEach(function (atmEvent) {
                        dataLayer.push(atmEvent);
                    });
                }
            });
        },
        globale: function () {
            // VINCE-363 Subscribe and handle OnCheckoutStepLoaded GlobalE events
            try {
                if (gle) {
                    gle('OnCheckoutStepLoaded', function (data) {
                        if (data && 'StepId' in data && data.details) {
                            var gleDetails = data.details;
                            var atmData = {};

                            try {
                                atmData = JSON.parse($('#atmData').val());
                            } catch(e) {
                                console.warn(e);
                            }

                            switch (data.StepId) {
                                case data.Steps.LOADED:
                                    // Hanlded via initial rendering events
                                    break;
                                case data.Steps.CONFIRMATION:
                                    if (data.IsSuccess) {
                                        // Translate to ATM event
                                        var atmEvent = {
                                            event: 'Order Placed',
                                            cart: {
                                                cartID: atmData.cartID || ''
                                            },
                                            transaction: {
                                                item: getListingItemsFromGLEProduct(gleDetails),
                                                payment: [{
                                                    paymentAmount: '' + gleDetails.customerTotalPrice,
                                                    paymentID: gleDetails.paymentMethodCode || '',
                                                    paymentMethod: gleDetails.paymentMethodName || ''
                                                }],
                                                purchaseID: data.OrderId,
                                                total: {
                                                    currency: gleDetails.customerCurrency,
                                                    numPayments: 1 //gleDetails.PaymentMethods.length
                                                },
                                                transactionID: data.MerchantOrderId || ''
                                            }
                                        };

                                        dataLayer.push(atmEvent);
                                    }
                                    break;
                            }
                        }
                    });
                }
            } catch (e) {
                console.warn(e);
            }
        },
        // events that should happen on every page
        all: function () {
            // Fire product tile click event
            var elems = 'div#maincontent>div:not(.product-detail) .product-tile .tile-body .product-link, div#maincontent>div:not(.product-detail) .image-container a:not(.quickview)';
            $('body').on('click', elems, function (e) {
                try {
                    var priceType = $(this).closest('.product').data('pricetype');
                    var productLink = $(this).parent().closest('.product-tile').find('.pdp-link .link');
                    if (productLink.attr('data-atmdata')) {
                        // Dynamically set product-tile position data
                        $('.product-tile .pdp-link .link').each(function (index) {
                            $(this).attr('data-position', index + 1);
                        });

                        var position = productLink.attr('data-position');
                        var productObj = $.parseJSON(productLink.attr('data-atmdata'));
                        productObj.priceType = priceType || 'Regular';
                        productTileClick(productObj, position);
                    }
                } catch(e) {
                    console.warn(e);
                }
            });

            $('body').on('search:sort', function (e, data) {
                try {
                    dataLayer.push(getSearchUpdateGridEvent('Listing Sort Order Changed', data));
                } catch (e) {
                    console.warn(e);
                }   
            });

            $('body').on('search:filter', function (e, data) {
                try {
                    var event = getSearchUpdateGridEvent(
                        $(data.currentTarget).find('span').first().hasClass('selected') ?
                        'Listing Filter Removed' : 'Listing Filter Added',
                        $(data.currentTarget).data('href'));
                    delete event.listingRefined.sortOrder;
                    dataLayer.push(event);   
                } catch (e) {
                    console.warn(e);
                }   
            });

            $('body').on('cart:update', function (e, data) {
                if (data && data.atmEvents) {
                    var atmEvents = data.atmEvents;
                    atmEvents.forEach(function (atmEvent) {
                        dataLayer.push(atmEvent);
                    });
                }
            });

            $('body').on('product:afterAddToCart', function (e, data) {
                if (data && data.atmEvents) {
                    var atmEvents = data.atmEvents;
                    atmEvents.forEach(function (atmEvent) {
                        dataLayer.push(atmEvent);
                    });
                }
            });

            $('body').on('tagmanager:recommendationsLoaded', function() {
                try {
                    var recommendationSlides = $('.recommendations .splide__slide:not(.splide__slide--clone)');
                    if (recommendationSlides.length > 0) {
                        var action = $('.page').data('action');
                        var pid = $('.product-detail').data('pid');
                        var qs = $('.page').data('querystring');
                        var cgid = qs && qs.split('cgid=')[0];
                        var recommendationParentProductID =
                            action === 'Cart-Show' ? 'cart' :
                            pid ? pid : 
                            cgid ? cgid :
                            'none';
                        var event = {
                            event: "Related Products Viewed",
                            relatedProducts: {
                                item: recommendationSlides.get().map(function(slide) {
                                    var product = $(slide).find('.product');
                                    return {
                                        itemPosition: $(slide).index() + 1,
                                        price: {
                                            priceType: product.data('priceType') || 'Regular'
                                        },
                                        productInfo: {
                                            name: product.data('name'),
                                            productID: product.data('pid'),
                                            sku: product.data('sku')
                                        },
                                        recommendationParentProductID: recommendationParentProductID
                                    };
                                })
                            }
                        };
                        dataLayer.push(event);
                    }    
                } catch (e) {
                    console.warn(e);
                }
            });

            // viewed event for the Waer It With recommender
            $(document).ready(function() {
                try {
                    var recommendationSlides = $('.also-shown .splide__slide:not(.splide__slide--clone)');
                    if (recommendationSlides.length > 0) {
                        var action = $('.page').data('action');
                        var pid = $('.product-detail').data('pid');
                        var qs = $('.page').data('querystring');
                        var cgid = qs && qs.split('cgid=')[0];
                        var recommendationParentProductID =
                            action === 'Cart-Show' ? 'cart' :
                            pid ? pid : 
                            cgid ? cgid :
                            'none';
                        var event = {
                            event: "Wear it With",
                            relatedProducts: {
                                item: recommendationSlides.get().map(function(slide) {
                                    var product = $(slide).find('.product');
                                    return {
                                        itemPosition: $(slide).index() + 1,
                                        price: {
                                            priceType: product.data('priceType') || 'Regular'
                                        },
                                        productInfo: {
                                            name: product.data('name'),
                                            productID: product.data('pid'),
                                            sku: product.data('sku')
                                        },
                                        recommendationParentProductID: recommendationParentProductID
                                    };
                                })
                            }
                        };
                        dataLayer.push(event);
                    }    
                } catch (e) {
                    console.warn(e);
                }
            });

            $('body').on('click', '.recommendations .splide__slide, .also-shown .splide__slide', function() {
                try {
                    var product = $(this).find('.product');
                    var event = {
                        event: "Related Product Clicked",
                        relatedProducts: {
                            item: [
                                {
                                    itemPosition: $(this).index() + 1,
                                    price: {
                                        priceType: product.data('priceType') || 'Regular'
                                    },
                                    productInfo: {
                                        name: product.data('name'),
                                        productID: product.data('pid'),
                                        sku: product.data('sku') || product.data('pid')
                                    }
                                }
                            ]
                        }
                    }
                    dataLayer.push(event);
                } catch (e) {
                    console.warn(e);
                }
            });

            $('body').on('click', '.back-to-pdp', function() {
                try {
                    var productData = $(this).attr('data-atmdata');
                    var productObj = [];
                    if (productData) {
                        productObj = JSON.parse(productData);
                    }
                    var event = {
                        "event": "Cart Product Click",
                        "product": productObj
                    }
                    dataLayer.push(event);
                } catch (e) {
                    console.warn(e);
                }
            });

            $('.footer-item-social li.footer-icon').on('click', function() {
                try {
                    var network = $(this).find('a').attr('href').split('.')[1];

                    var event = {
                        event: "Social Brand Followed",
                        social: {
                            socialNetwork: network
                        }
                    }

                    dataLayer.push(event);
                } catch(e) {
                    console.warn(e);
                }
            });

            $('body').on('tagmanager:forwardevents', function(e, events) {
                var eventList = events || e.detail;
                forwardEvents(eventList);
            });

            $('body').on('tagmanager:flushevents', function() {
                forwardEvents(queuedEvents);
                queuedEvents = [];
            });

            $('body').trigger('tagmanager:flushevents');

            $('body').on('click', '.account-logout', function() {
                try {
                    var event = {
                        event: "User Signed Out",
                        user: {
                            custKey: window.atmQueuedEvents[1].user.custKey,
                            loginStatus: "logged out",
                            userType: window.atmQueuedEvents[1].user.userType
                        }
                    }
                    dataLayer.push(event);
                } catch(e) {
                    console.warn(e);
                }
            });
        }
    };
}

/**
 *
 * @description Initialize the tag manager functionality
 * @param {string} nameSpace The current name space
 */
exports.init = function (nameSpace) {
    try {
        if (!ATM_ENABLED || tagManagerInitCompleted) return;

        var ns = nameSpace;

        if (!ns) {
            var nameSpaceMappings = {
                'Home-Show': 'home',
                'Default-Start': 'home',
                'Product-Show': 'product',
                'Cart-Show': 'cart',
                'Globale-CheckoutShow': 'globale',
                'ContactUs-Landing': 'contactUs',
                'Login-Show': 'login',
                'Checkout-Begin': 'checkout',
            };

            var action = $('.page').data('action');

            if (action) {
                ns = nameSpaceMappings[action];
            }
        }

        if (ns && events[ns]) { events[ns](); }
        events.all();

        tagManagerInitCompleted = true;
    } catch (e) {
        console.warn(e);
    }
};
