'use strict';

var cart = require('../cart/cart');

var updateMiniCart = true;

module.exports = function () {
    cart();

    $('.minicart').on('count:update', function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            $('.minicart .minicart-quantity').text(count.quantityTotal);
            $('.minicart .minicart-quantity').attr('data-quantity', count.quantityTotal);
            $('.minicart .minicart-modal__head-count').text(count.quantityTotal);
            $('.minicart .minicart-link').attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });
        }
    });

    $('body').on('click touchend', '.minicart', function (e) {
        // 991 and below (tablet and below), do not show minicart
        var minicart = $(this);

        // Don't show when top bar isn't visible
        // Additionally, prevent flyout closing to bubble up into a re-opening
        if ($('.search:visible').length === 0 || $(e.target).closest('.js-panel-close').length) {
            return;
        }
        var url = $( minicart).data('action-url');
        var count = parseInt($(minicart).find('.minicart-quantity').text(), 10);
        var minicartCount = $(minicart).find('.minicart-link').text();

        if (count !== 0 && minicart.find('.js-flyout.show').length === 0) {
            minicart.find('.js-flyout').addClass('show');
            $(minicart.find('.js-flyout')).trigger('flyout.shown');

            if (!updateMiniCart) {
                return;
            }

            minicart.find('.js-flyout-content').spinner().start();
            $.get(url, function (data) {
                // Update all minicart instances so that updateMiniCart doesn't interfere
                $('.minicart').find('.js-flyout-content').empty();
                $('.minicart').find('.js-flyout-content').append(data);
                $('.minicart').find('.minicart-modal__head-count').text(minicartCount);
                updateMiniCart = false;
                $.spinner().stop();
            });
        }
    });
    
    $('body').on('flyout.hidden', function () {
        $('.minicart').trigger('minicart:cleartimeout');
    });
    $('.minicart').on('focusout', function (event) {
        if ((event.type === 'focusout' && $('.minicart').has(event.target).length > 0)
            || $('body').hasClass('modal-open')) {
            event.stopPropagation();
            return;
        }
        $('.minicart .js-flyout').removeClass('show')
            .trigger('flyout.hidden');
    });
    $('body').on('change', '.minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            location.reload();
        }
    });
    $('body').on('product:afterAddToCart', function () {
        updateMiniCart = true;
    });
    $('body').on('cart:update', function () {
        updateMiniCart = true;
    });
};
